import React, { useEffect, useState, useContext, useRef } from 'react'
import { Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import { getOpenAIChartData, getOpenAITableData, getOpenAIProbabilityData, getWorkOrderChartData, getWorkOrderTableData } from './apiCalls/util';
import { TextField, Typography } from '@mui/material';
import * as ChartGeo from 'chartjs-chart-geo'
import { Chart } from 'chart.js'
import Papa from 'papaparse'
import PotentialImpact from './charts/PotentialImpact';
import GeoMap from './charts/GeoMap';
import GoogleMaps from './charts/GoogleMaps';
import Geocode from "react-geocode";
import DataTable from './tables/DataTable';
import { continentList, fieldWorkerSwitch, orderSizeSwitch } from './charts/constants';
import InputField from './ui/InputField';
import { MenuItem } from '@mui/material';
import GoogleMapsWorkOrder from './charts/GoogleMapsWorkOrder';
import FieldEngineerByCity from './charts/FieldEngineerByCity';
import WorkOrderTable from './tables/WorkOrderTable';


const DashboardLanding = () => {
    const [mapData, setMapData] = useState([])
    const canvasRef = useRef(null);
    const [barChartData, setBarChartData] = useState([])
    const [probabilityChartData, setProbabilityChartData] = useState([])
    const [tableData, setTableData] = useState([])
    const [probTableData, setProbTableData] = useState([])
    const [selectedContinent, setSelectedContinent] = useState(continentList[0].value)

    const [workOrderMapData, setWorkOrderMapData] = useState([])
    const [workOrderBarData, setWorkOrderBarData] = useState([])
    const [workOrderTableData, setWorkOrderTableData] = useState([])
    const [workOrderBarOrderData, setWorkOrderBarOrderData] = useState([])

    Geocode.setApiKey(process.env.REACT_APP_API_KEY);
    Geocode.setLanguage("en");


    const ref = useRef(true);

    useEffect(() => {
        handleChartData()
        setInterval(() => handleChartData(), 3000);
    }, [])




    const handleChartData = async () => {
        // const data = await getOpenAIChartData();
        // const table = await getOpenAITableData();
        const wo_data = await getWorkOrderChartData();
        const wo_table = await getWorkOrderTableData();
        // const prob = await getOpenAIProbabilityData()
        // setTableData(table)
        setWorkOrderTableData(wo_table)
        // setProbTableData(prob)
        // console.log("read data", data)
        let newArray = []
        // for (let k = 0; k < data.length; k++) {
        //     newArray.push(data[k])
        //     Geocode.fromAddress('Midland, TEXAS').then(
        //         (response) => {
        //             console.log("response",response.results[0].formatted_address)
        //             newArray[k].latitude = response.results[0].geometry.location.lat;
        //             newArray[k].longitude = response.results[0].geometry.location.lng
        //         },
        //         (error) => {
        //             console.error('error geocode',error);

        //         }
        //     );
        // }
        setTimeout(() => {
            // setMapData(data)
            setWorkOrderMapData(wo_data)
        }, 1000);


        let chartJSDataObj = {
            labels: [],
            datasets: [
                {
                    label: "2,000,000 and below",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(40, 139, 226)',
                    backgroundColor: 'rgb(40, 139, 226)',
                },
                {
                    label: "2,000,000 to 5,000,000",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(0, 204, 177)',
                    backgroundColor: 'rgb(0, 204, 177)',
                },
                {
                    label: "5,000,000 to 8,000,000",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(232, 79, 110)',
                    backgroundColor: 'rgb(232, 79, 110)',
                },
                {
                    label: "8,000,000 to 11,000,000",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(248, 135, 42)',
                    backgroundColor: 'rgb(248, 135, 42)',
                },
                {
                    label: "11,000,000 to 14,000,000",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(254, 187, 32)',
                    backgroundColor: 'rgb(254, 187, 32)',
                },
                {
                    label: "above 14,000,000",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(144, 113, 204)',
                    backgroundColor: 'rgb(144, 113, 204)',
                },

            ],
        };
        let chartJSDataObjProbability = {
            labels: [],
            datasets: [
                {
                    label: "50 and below",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(40, 139, 226)',
                    backgroundColor: 'rgb(40, 139, 226)',
                },
                {
                    label: "50 to 100",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(0, 204, 177)',
                    backgroundColor: 'rgb(0, 204, 177)',
                },
                {
                    label: "100 to 150",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(232, 79, 110)',
                    backgroundColor: 'rgb(232, 79, 110)',
                },
                {
                    label: "150 to 200",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(248, 135, 42)',
                    backgroundColor: 'rgb(248, 135, 42)',
                },
                {
                    label: "200 to 250",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(254, 187, 32)',
                    backgroundColor: 'rgb(254, 187, 32)',
                },
                {
                    label: "above 250",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(144, 113, 204)',
                    backgroundColor: 'rgb(144, 113, 204)',
                },

            ],
        };


        // let labels = []
        // for (let i = 0; i < data.length; i++) {
        //     labels.push(data[i].city.trim())
        //     let index = costSwitch(parseFloat(data[i].cost))
        //     let probIndex = probSwitch(Math.round(data[i].total_probability * 100))
        //     chartJSDataObj.datasets[index].data.push(parseFloat(data[i].cost))
        //     chartJSDataObjProbability.datasets[probIndex].data.push(Math.round(data[i].total_probability * 100))
        //     for (let j = 0; j < chartJSDataObj.datasets.length; j++) {
        //         if (j != index) {
        //             chartJSDataObj.datasets[j].data.push(null);
        //         }
        //     }
        //     for (let j = 0; j < chartJSDataObjProbability.datasets.length; j++) {
        //         if (j != probIndex) {
        //             chartJSDataObjProbability.datasets[j].data.push(null);
        //         }
        //     }


        // }
        // chartJSDataObj.labels.push(...new Set(labels))
        // chartJSDataObjProbability.labels.push(...new Set(labels))
        // // console.log("Array", chartJSDataObjProbability)

        // setBarChartData(chartJSDataObj);
        // setProbabilityChartData(chartJSDataObjProbability)

        let chartJSDataObWorkOrder = {
            labels: [],
            datasets: [
                {
                    label: "1 and below",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(40, 139, 226)',
                    backgroundColor: 'rgb(40, 139, 226)',
                },
                {
                    label: "2 to 3",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(0, 204, 177)',
                    backgroundColor: 'rgb(0, 204, 177)',
                },
                {
                    label: "3 to 5",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(232, 79, 110)',
                    backgroundColor: 'rgb(232, 79, 110)',
                },
                {
                    label: "5 to 7",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(248, 135, 42)',
                    backgroundColor: 'rgb(248, 135, 42)',
                },
                {
                    label: "7 to 10",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(254, 187, 32)',
                    backgroundColor: 'rgb(254, 187, 32)',
                },
                {
                    label: "above 10",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(144, 113, 204)',
                    backgroundColor: 'rgb(144, 113, 204)',
                },

            ],
        };
        let chartJSDataObjWorkOrderSize = {
            labels: [],
            datasets: [
                {
                    label: "10 and below",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(40, 139, 226)',
                    backgroundColor: 'rgb(40, 139, 226)',
                },
                {
                    label: "10 to 20",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(0, 204, 177)',
                    backgroundColor: 'rgb(0, 204, 177)',
                },
                {
                    label: "20 to 30",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(232, 79, 110)',
                    backgroundColor: 'rgb(232, 79, 110)',
                },
                {
                    label: " 30 to 40",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(248, 135, 42)',
                    backgroundColor: 'rgb(248, 135, 42)',
                },
                {
                    label: "40 to 50",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(254, 187, 32)',
                    backgroundColor: 'rgb(254, 187, 32)',
                },
                {
                    label: "above 50",
                    data: [],
                    borderWidth: 0.5,
                    color: 'rgb(144, 113, 204)',
                    backgroundColor: 'rgb(144, 113, 204)',
                },

            ],
        };

        let woLabels = []
        for (let i = 0; i < wo_data.length; i++) {

            woLabels.push(wo_data[i].city.trim())
            let index = fieldWorkerSwitch(parseInt(wo_data[i].total_field_engineer))
            let probIndex = orderSizeSwitch(parseInt(wo_data[i].job_size))
            chartJSDataObWorkOrder.datasets[index].data.push(parseInt(wo_data[i].total_field_engineer))
            chartJSDataObjWorkOrderSize.datasets[probIndex].data.push(parseInt(wo_data[i].job_size))
            for (let j = 0; j < chartJSDataObWorkOrder.datasets.length; j++) {
                if (j != index) {
                    chartJSDataObWorkOrder.datasets[j].data.push(null);
                }
            }
            for (let j = 0; j < chartJSDataObjWorkOrderSize.datasets.length; j++) {
                if (j != probIndex) {
                    chartJSDataObjWorkOrderSize.datasets[j].data.push(null);
                }
            }


        }
        chartJSDataObWorkOrder.labels.push(...new Set(woLabels))
        chartJSDataObjWorkOrderSize.labels.push(...new Set(woLabels))
        setWorkOrderBarData(chartJSDataObWorkOrder);
        console.log("oder sd", chartJSDataObjWorkOrderSize)
        setWorkOrderBarOrderData(chartJSDataObjWorkOrderSize)
    }

    const costSwitch = (data) => {
        // let data = parseFloat(res)
        if (data < 2000000) return 0
        else if (data > 2000000 && data < 5000000) return 1
        else if (data > 5000000 && data < 8000000) return 2
        else if (data > 8000000 && data < 11000000) return 3
        else if (data > 11000000 && data < 14000000) return 4
        else if (data > 14000000) return 5
        else return 5

    }
    const probSwitch = (data) => {
        // let data = parseFloat(res)
        if (data < 50) return 0
        else if (data > 50 && data < 100) return 1
        else if (data > 100 && data < 150) return 2
        else if (data > 150 && data < 200) return 3
        else if (data > 200 && data < 250) return 4
        else if (data > 250) return 5
        else return 5

    }



    // console.log("work order map",workOrderMapData)

    return (
        <Grid item container xs={12} justifyContent="center">
            {/* <Grid xs={12} container sx={{ justifyContent: "center" }}>
                <Typography variant="h4">Risk Dashboard</Typography>
            </Grid>
            <Grid xs={12} container sx={{ justifyContent: "center" }}>
                <Typography variant="body1">A complete analysis on Cost</Typography>
            </Grid> */}
            <Grid xs={12} container sx={{ justifyContent: "center", backgroundColor: "#F9F6EE" }}>
                <Grid xs={12} container sx={{
                    backgroundColor: "#D3D3D3", alignItems: "center",
                    paddingLeft: 3, paddingTop: 1, paddingBottom: 1
                }}>
                    <Grid xs={6}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{selectedContinent} Map Work Orders</Typography>
                    </Grid>
                    <Grid xs={6} container style={{ justifyContent: "flex-end" }}>
                        <TextField name="continent"
                            select={true}
                            value={selectedContinent}
                            style={{
                                backgroundColor: "white",
                                borderRadius: "4px",
                                width: 240, marginRight: 5, height: 40
                            }}
                            SelectProps={{
                                style: { height: 40 },
                            }}
                            onChange={(e, value) => {
                                setSelectedContinent(e.target.value);

                            }} >
                            {continentList.map((option) => (
                                <MenuItem sx={{ height: 40, paddingLeft: 2, paddingTop: 0, paddingBottom: 0, paddingRight: 0 }} key={option.key} value={option.value}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                {/* <Grid xs={12} container sx={{ justifyContent: "center", padding: 1 }}>
                    <GoogleMaps chartData={mapData} selectedContinent={selectedContinent}/>
                </Grid> */}
                <Grid xs={12} container sx={{ justifyContent: "center", padding: 1 }}>
                    <GoogleMapsWorkOrder chartData={workOrderMapData} selectedContinent={selectedContinent} />
                </Grid>
            </Grid>
            <Grid xs={12} container sx={{ justifyContent: "center", marginTop: 2 }}>
                {/* <Grid xs={9} container >
                    <PotentialImpact title='Potential Impact by City' subTitle='Potential Cost'
                        chartData={barChartData} showAllTicks={false} />
                </Grid> */}
                <Grid xs={9} container >
                    <FieldEngineerByCity title='Field Engineer Needed by City' subTitle='Field Engineer'
                        chartData={workOrderBarData} showAllTicks={false} />
                </Grid>
                {/* <Grid xs={3} container >
                    <DataTable rows={tableData} title="Largest Problems" showProbability={false} />
                </Grid> */}
                <Grid xs={3} container >
                    <WorkOrderTable rows={workOrderTableData} title="Largest Problems" showProbability={false} />
                </Grid>
            </Grid>
            <Grid xs={12} container sx={{ justifyContent: "center", marginTop: 2 }}>
                {/* <Grid xs={10} container >
                    <PotentialImpact title='Likelihood by City' subTitle='Probability'
                        chartData={probabilityChartData} showAllTicks={true} />
                </Grid> */}
                {/* <Grid xs={2} container >
                    <DataTable rows={tableData} title="Average Probability by City" showProbability={true} />
                </Grid> */}
                <FieldEngineerByCity title='Order Size by City' subTitle='Order Size'
                    chartData={workOrderBarOrderData} showAllTicks={true} />
            </Grid>
        </Grid>
    )
}

export default DashboardLanding