import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Bar } from 'react-chartjs-2'

const FieldEngineerByCity = (props) => {

  const { title, subTitle, chartData, tableData, chartIndex, showAllTicks} = props;
  // console.log("Dataset chart dtaa",chartData)
  let average = 0;
  if ("datasets" in chartData) {
    average =
      chartData.datasets[0].data.reduce((a, b) => a + b, 0) /
      chartData.datasets[0].data.length;
  }
  const chartOptions = {
    elements: {
      point: {
        radius: 10,
      },
      bar: {
        borderWidth: 3,
        borderRadius: "10px",

      },
      layout: {
        padding: {
          top: 60,
        },
      },
    },
    barThickness: 23,
    borderRadius: 3,
    skipNull:true,
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {

      barRoundness: 1,
      legend: {
        title: {
          text: subTitle,
          color: "black",
          display: true
        },
        display: true,
        onHover: (event, legendItem, legend) => {
          const activeElement = {
            datasetIndex: 0,
            index: legendItem.index
          };
          // console.log("active ele", event, legendItem, legend)
        },
        labels: {
          color: "black",
          usePointStyle: true,
          pointStyle: 'rect',

        },
        position: "right",
        align: "start"
      },
      datalabels: {
        display: false,
        clamp: true,
        formatter: (val, context) => { if (val > 0) 
          {if(showAllTicks){
            Math.round(val * 100) 
          }else return `${Math.round(val * 100) / 100}K` } 
          else return null },
        anchor: "end",
        align: "end",
      },
      tooltip: {
        callbacks: {
            label: function(context) {
            //   console.log("contect",context)
                let label =showAllTicks? 'Total Order Size: ' + context.formattedValue : 'Total Field Engineer: ' + context.formattedValue || '';
                
                return label;
            }
        }
    }
      // title: {
      //   display: true,
      //   text: "Budget Optimization",
      // },
    },
    scales: {
      x: {
        grid: {
          lineWidth: 1,
        },
        border: {
          dash: [0, 5],
        },
        // ticks: {
        //   // For a category axis, the val is the index so the lookup via getLabelForValue is needed
        //   callback: function (val, index) {
        //     // Hide every 2nd tick label
        //     return index % 2 === 0 ? this.getLabelForValue(val) : '';
        //     return showAllTicks? this.getLabelForValue(val):index % 2 === 0 ? this.getLabelForValue(val) : '';
        //   },

        // }

      },

      y: {        
        title: {
        text: subTitle,
        color: "black",
        display: true
      },
        grid: {
          lineWidth: 1,
        },
        border: {
          dash: [5, 0],
        },
      },
    },
  };
  return (
    <Grid xs={12} style={{ maxHeight: 500, padding: 2 }}>
      <Grid xs={12} sx={{
        justifyContent: "center", backgroundColor: "#D3D3D3",
        paddingLeft: 3, paddingTop: 1, paddingBottom: 1
      }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{title}</Typography>
      </Grid>

      <div style={{ height: 400, width: '100%', padding:5 }}>
        {"datasets" in chartData ? <Bar options={chartOptions} data={chartData} /> : undefined}
      </div>
    </Grid>
  )
}

export default FieldEngineerByCity