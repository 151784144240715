

export const continentKey = Object.freeze({
    NorthAmerica: 0, Europe: 1, SouthAmerica: 2, Asia: 3, Africa: 4,Florida:5,MiddleEast:6
});

export const continentNames = Object.freeze({
    [continentKey.NorthAmerica]: 'North America',
    [continentKey.Europe]: 'Europe',
    [continentKey.SouthAmerica]: 'South America',
    [continentKey.Asia]: 'Asia',
    [continentKey.Africa]: 'Africa',
    [continentKey.Florida]: 'Florida',
    [continentKey.MiddleEast]: 'Middle East',
})

export const continent = Object.freeze({
    'North America': 0,
    'Europe': 1,
    'South America': 2,
    'Asia': 3,
    'Africa': 4,
    'Florida':5,
    'Middle East':6
})

export const continentList = [
    { key: [continentKey.NorthAmerica], value: continentNames[continentKey.NorthAmerica] },
    { key: [continentKey.Europe], value: continentNames[continentKey.Europe] },
    { key: [continentKey.SouthAmerica], value: continentNames[continentKey.SouthAmerica] },
    { key: [continentKey.Asia], value: continentNames[continentKey.Asia] },
    { key: [continentKey.Africa], value: continentNames[continentKey.Africa] },
    { key: [continentKey.Florida], value: continentNames[continentKey.Florida] },
    { key: [continentKey.MiddleEast], value: continentNames[continentKey.MiddleEast] },

]

export const mapCenters = Object.freeze({
    [continentKey.NorthAmerica]: {
        center: {
            lat: 39.24941419846305,
            lng: -98.65058958804141
        },
        zoom: 4.5
    },
    [continentKey.Europe]: {
        center: {
            lat: 48.50287749038302,
            lng: 14.115561259179156
        },
        zoom: 4.4
    },
    [continentKey.SouthAmerica]: {
        center: {
            lat: -15.01016854197985,
            lng: -57.5710818823775
        },
        zoom: 4.3
    },
    [continentKey.Asia]: {
        center: {
            lat: 28.89252125899,
            lng: 88.71589364654534
        },
        zoom: 4.2
    },
    [continentKey.Africa]: {
        center: {
            lat: 4.855466215197959,
            lng: 19.95764894924912
        },
        zoom: 3.8
    },
    [continentKey.Florida]: {
        center: {
            lat: 28.563196368863935,
            lng: -81.81910546069403
        },
        zoom: 6.8
    },
    [continentKey.MiddleEast]: {
        center: {
            lat: 25.985243826496834,
            lng: 45.82192865776935
        },
        zoom: 5.2
    },
    7: {
        center: {
            lat: 16.422425795658278,
            lng: 18.2392475463257
        },
        zoom: 4.6
    }

})

export const orderSizeColorSwitch = (data) => {
    // let data = parseFloat(res)
    if (data <= 10) return 'rgb(40, 139, 226)'
    else if (data > 10 && data <= 20) return 'rgb(0, 204, 177)'
    else if (data > 20 && data <= 30) return 'rgb(232, 79, 110)'
    else if (data >30 && data <= 40) return 'rgb(248, 135, 42)'
    else if (data >40  && data <= 50) return 'rgb(254, 187, 32)'
    else if (data > 50) return 'rgb(144, 113, 204)'
    else return '#F8C8DC'

}

export const fieldWorkerSwitch=(data)=>{
    if (data <= 1) return 0
    else if (data > 1 && data <= 3) return 1
    else if (data > 3 && data <= 5) return 2
    else if (data > 5 && data <= 7) return 3
    else if (data > 7 && data <= 10) return 4
    else if (data > 10) return 5
    else return 5
}

export const orderSizeSwitch = (data) => {
    // let data = parseFloat(res)
    if (data <= 10) return 0
    else if (data > 10 && data <= 20) return 1
    else if (data > 20 && data <= 30) return 2
    else if (data >30 && data <= 40) return 3
    else if (data >40  && data <= 50) return 4
    else if (data > 50) return 5
    else return 5

}

