import React from 'react'
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import { alpha, styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "white", 
    borderRadius: "4px" ,
    width:240,
    inputRoot: {
        '&$disabled': {
            backgroundColor: 'gray'
        },
        padding:'0px'
    },
    disabled: {},
}));


const InputField = (props) => {
    const {label,name,required,multiline,rowsMax,value,onChange,type,placeholder,disabled,
        selectProp,children,defaultValue,InputProps,autoFocused,inputRef}=props
  return (
    <Grid container xs={12} style={{width:"100%"}} alignItems="center"> 

    {label && <InputLabel shrink htmlFor="bootstrap-input" style={{ color: "black",fontSize:15 ,width:60}} >
        {label} {required && <span style={{ color: "red", fontSize: 15 }}>*</span>}
    </InputLabel>}

    <StyledTextField
        variant="outlined"
        margin="normal"
        size="small"
        autoFocused={autoFocused}
        inputRef={inputRef}
        // fullWidth={true}
        required={required}
        multiline={multiline}
        rows={rowsMax}
        id={name}
        defaultValue={defaultValue}
        value={value}
        name={name}
        onChange={onChange}
        type={type && type}
        placeholder={placeholder}
        FormHelperTextProps={{
            backgroundColor: "#1f2344",
            variant: "filled",
            margin: "0px",
        }}
        disabled={disabled}
        select={selectProp}
        InputProps={InputProps}
    >{children}
        </StyledTextField>
    
</Grid>
  )
}

export default InputField