export async function getOpenAIChartData(filterData) {
  try {
    // console.log('url',process.env.REACT_APP_FLASK_URL,process.env.NODE_ENV);
    let url = process.env.REACT_APP_FLASK_URL + "getOpenAIChartData";
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getOpenAITableData(filterData) {
  try {
    // console.log(JSON.stringify(filterData));
    let url = process.env.REACT_APP_FLASK_URL + "getOpenAITableData";
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getOpenAIProbabilityData(filterData) {
  try {
    // console.log(JSON.stringify(filterData));
    let url = process.env.REACT_APP_FLASK_URL + "getOpenAIProbabilityData";
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function sendEmailToUser(emailData) {
  try {
    // console.log(JSON.stringify(filterData));
    let url = process.env.REACT_APP_FLASK_URL + "sendEmailToUser";
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(emailData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getWorkOrderChartData(filterData) {
  try {
    // console.log('url',process.env.REACT_APP_FLASK_URL,process.env.NODE_ENV);
    let url = process.env.REACT_APP_FLASK_URL + "getWorkOrderChartData";
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getWorkOrderTableData(filterData) {
  try {
    // console.log(JSON.stringify(filterData));
    let url = process.env.REACT_APP_FLASK_URL + "getWorkOrderTableData";
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}


export async function sendWorkOrderEmailToUser(emailData) {
  try {
    // console.log(JSON.stringify(filterData));
    let url = process.env.REACT_APP_FLASK_URL + "sendWorkOrderEmailToUser";
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(emailData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}