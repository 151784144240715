import "./App.css";
import { useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BubbleController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ThemeProvider, createTheme ,useTheme} from '@mui/material/styles';
import DashboardLanding from "./component/DashboardLanding";
import Home from "./component/Home";
import ErrorPage from "./component/ErrorPage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BubbleController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  ChartDataLabels
);

class Custom extends BubbleController {
  draw() {
      // Call bubble controller method to draw all the points
      super.draw(arguments);

      // Now we can do some custom drawing for this dataset. Here we'll draw a red box around the first point in each dataset
      const meta = this.getMeta();
      const pt0 = meta.data[0];
      const ctx = this.chart.ctx;
    // console.log("meta",meta)
    ctx.save();
    ctx.fillStyle = 'red';
    ctx.textBaseline = 'bottom'; 
  
      ctx.restore();
  }
};
Custom.id = 'bubbleMap';
Custom.defaults = BubbleController.defaults;

ChartJS.register(Custom);

function App() {

    const router = createBrowserRouter([
      { path: "/", element: <Home />, errorElement: <ErrorPage /> },
      {
        path: "/dashboard",
        element: <DashboardLanding />,
      },
     
    ]);
    
    let theme = useTheme()
  
    theme = createTheme(theme, {
      components: {
        MuiTab: {
          styleOverrides: {
            root:{
              "&.Mui-selected": {
                // background:'linear-gradient(90deg, rgba(6, 11, 92, 0.9) 0%, rgba(0,0,0,0.6) 100%)',
                // color:"white",
                borderRight:2,
                borderColor:"red"
              
              },
              color:"black",
              // backgroundColor:"gray"
              
            }
          }
        }
      }
    })
  return (
    <ThemeProvider theme={theme}>

        <RouterProvider router={router}></RouterProvider>
    </ThemeProvider>
  );
}

export default App;
