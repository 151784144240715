import React, { useEffect, useState, useContext } from 'react'
import { Container } from "@mui/system";
import Grid from "@mui/material/Grid";
import { createTheme } from '@mui/material/styles';
import DashboardLanding from './DashboardLanding';
import '../App.css'
import Appbar from './Appbar';


const Home = () => {




  return (
    <Grid className='App'>
      <Grid item container xs={12}>
        <Grid item container xs={12}>
          <Appbar />
        </Grid>

        <Grid item xs={12} container sx={{ marginTop: 9.5, padding: 2 }} ><DashboardLanding /></Grid>
      </Grid>

    </Grid>

  )
}

export default Home