import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TitleIcon from '@mui/icons-material/Title';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PublicIcon from '@mui/icons-material/Public';
import TableChartIcon from '@mui/icons-material/TableChart';
import PercentIcon from '@mui/icons-material/Percent';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    '& .MuiDataGrid-cell': {
      color:
        theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
   
  }));
  

export default function DataTable({ rows, title ,showProbability}) {
    let count = rows.length
    const columns =showProbability? 
    [
        { field: 'id', headerName: 'ID', width: 10 ,
        renderHeader: (params) => (
            <Typography variant='subtitle2'><TableChartIcon sx={{fontSize:12}} /></Typography>
          ),
          valueGetter: (params) => params.id
        },
        {
            field: 'city',
            headerName: 'City',
            type: 'number',
            width: 110,
            editable: false,
            renderHeader: (params) => (
                <Typography variant='subtitle2'><LocalAtmIcon sx={{fontSize:12}} /> City</Typography>
              ),
        },
        {
            field: 'probability',
            headerName: 'Probability',
            width: 110,
            editable: false,
            renderHeader: (params) => (
                <Typography variant='subtitle2'><PercentIcon sx={{fontSize:12}} /> Probability</Typography>
              ),
              valueGetter: (params) => Math.round(params.value *100).toFixed(2),
        },

    ]
    : [
        { field: 'id', headerName: 'ID', width: 10 ,
        renderHeader: (params) => (
            <Typography variant='subtitle2'><TableChartIcon sx={{fontSize:12}} /></Typography>
          ),
          valueGetter: (params) => params.id
        },
        {
            field: 'problem',
            headerName: 'Problem',
            width: 110,
            editable: false,
            renderHeader: (params) => (
                <Typography variant='subtitle2'><TitleIcon sx={{fontSize:12}} /> Problem</Typography>
              ),
            valueGetter: (params) => params.value.charAt(0).toUpperCase() + params.value.slice(1),
    
            // word.charAt(0).toUpperCase() + word.slice(1))
        },
        {
            field: 'cost',
            headerName: 'Potential Cost',
            type: 'number',
            width: 110,
            editable: false,
            renderHeader: (params) => (
                <Typography variant='subtitle2'><LocalAtmIcon sx={{fontSize:12}} /> Potential Cost</Typography>
              ),
        },
        {
            field: 'city',
            headerName: 'City',
            width: 110,
            editable: false,
            renderHeader: (params) => (
                <Typography variant='subtitle2'><PublicIcon sx={{fontSize:12}} /> City</Typography>
              ),
        },
    ];
    
    // console.log("rows input", rows)
    return (
        <Grid xs={12} style={{ maxHeight: 500, padding: 2 }}>
            <Grid xs={12} sx={{
                justifyContent: "center", backgroundColor: "#D3D3D3",
                paddingLeft: 3, paddingTop: 1, paddingBottom: 1
            }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{title}</Typography>
            </Grid>
            <Box sx={{ height: 400, width: '100%',border:'1px solid #F9F6EE' }}>

                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                        },
                        sorting: {
                            sortModel:showProbability? [{ field: 'probability', sort: 'desc' }]:[],
                          },
                    }}

                    pageSizeOptions={[5]}
                    disableRowSelectionOnClick
                />
            </Box>
        </Grid>
    );
}