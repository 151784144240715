import React, { useEffect, useState,useRef } from "react";
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Box, Button, Typography, Grid, Icon, IconButton, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/Mail';
import InputField from "../ui/InputField";
import { sendWorkOrderEmailToUser } from "../apiCalls/util";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoneIcon from '@mui/icons-material/Done';
import CircularProgress from "@mui/material/CircularProgress";
import SendIcon from '@mui/icons-material/Send';
import { mapCenters,continent,orderSizeColorSwitch } from "./constants";


const ToBeStyledTooltip = ({ className, ...props }) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
);
const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 240,
    border: '1px solid purple',
    // borderWidth:2,
    borderColor: "purple",
    borderTopWidth: 2, borderLeftWidth: 1, borderRightWidth: 1, borderBottomWidth: 1,
}));

const K_WIDTH = 40;
const K_HEIGHT = 40;

const greatPlaceStyle = (size) => {
    let x = {
        position: 'absolute',
        // width: size,
        // height: size,
        width: Math.round((size / 10) * 5),
        height: Math.round((size / 10) * 5),
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,
        border: '0.8px solid purple',
        borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, borderBottomWidth: 2,
        borderColor: orderSizeColorSwitch(size),
        borderRadius: '50%',
        backgroundColor: orderSizeColorSwitch(size),
        boxShadow: "1px solid purple",
        textAlign: 'center',
        // color: 'blue',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4,
        opacity: 0.6
    }
    return x
};




const BubbleComponent = (props) => {
    const ref =useRef(null)

    const loseFocus =()=>{
        ref.current.blur()
    }

    return <Tooltip arrow describeChild
    PopperProps={{
        sx: {
            "& .MuiTooltip-tooltip": {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                maxWidth: 300,
                border: '1px solid purple',
                // borderWidth:2,
                borderColor: orderSizeColorSwitch(props.size),
                borderTopWidth: 3, borderLeftWidth: 1, borderRightWidth: 1, borderBottomWidth: 1,
            }
        }
    }}
    title={<Grid item xs={12} container >
        <Grid item xs={12} container sx={{ justifyContent: "space-between" }}>
            <Grid item xs={6} >
                <Typography variant="caption" >City: </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right", }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>{props.text.City}</Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} container sx={{ justifyContent: "space-between" }}>
            <Grid item xs={6} >
                <Typography variant="caption" >Total Field Engineer(s): </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right", }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>{props.text.totalWorker}</Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} container sx={{ justifyContent: "space-between" }}>
            <Grid item xs={6} >
                <Typography variant="caption" >Total Hours: </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right", }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>{props.text.totalHours}</Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} container sx={{ justifyContent: "space-between" }}>
            <Grid item xs={6} >
                <Typography variant="caption" >Total Order Size: </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right", }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>{props.text.orderSize}</Typography>
            </Grid>
        </Grid>

        {props.emailDisplay ?
            <Grid item xs={12} sx={{ justifyContent: "space-between" }}>
    
                <Grid xs={12}>
                    <InputField label="To" name="email_to"
                    onBlur={()=>console.log("onblur claled")}
                        value={props.sendToEmail} required={true}
                        onChange={(e, value) => {
                            props.setSendToEmail(e.target.value);

                        }} />
                </Grid>
                <Grid xs={12}>
                    <InputField label="Notes" name="notes"
                    // ref={ref}
                     inputRef={ref}
                        value={props.notes} required={true} multiline={true} rowsMax={2}
                        onChange={(e, value) => {
                            props.setNotes(e.target.value);

                        }} />
                </Grid>
                <Grid xs={12} container justifyContent='flex-end' sx={{ alignItems: 'center' }}>

                    {props.messageSent ?
                        <><CheckCircleOutlineIcon
                            sx={{ color: "green", fontSize: 18, paddingRight: 0.3 }} />
                            <Typography variant="caption" >{props.messageSent}</Typography></> :
                        props.progress ?
                            <CircularProgress size={20} />
                            :
                            <Button  onMouseEnter={loseFocus}
                            onClick={()=>props.handleEmailSubmit(props.text)}
                            >Send</Button>
                            }


                    <IconButton onClick={() => {
                        props.setNotes('')
                        props.setSendToEmail('')
                        props.setMessageSent(false)
                        props.setEmailDisplay(!props.emailDisplay)
                    }} sx={{ paddingRight: 0 }} >
                        <CloseIcon /></IconButton>
                </Grid>
            </Grid>
            : <Grid item xs={12} container justifyContent='flex-end'>
                <IconButton onClick={() => props.setEmailDisplay(!props.emailDisplay)}
                    sx={{ paddingRight: 0 }}
                ><MailIcon /></IconButton>
            </Grid>}
    </Grid>}
    placement="top">
    <Icon style={greatPlaceStyle(props.size)} onMouseOver={() => {
        props.setOpen(props.text.City)
    }}
></Icon>
</Tooltip> 
}


export default function GoogleMapsWorkOrder(props) {
    const {chartData,selectedContinent}=props
    // console.log("chartData",chartData)
    const [emailDisplay, setEmailDisplay] = useState(false)
    const [sendToEmail, setSendToEmail] = useState('')
    const [notes, setNotes] = useState('')
    const [open, setOpen] = useState(false)
    const [messageSent, setMessageSent] = useState(false)
    const [progress, setProgress] = useState(false)
    const [places,setPlaces]=useState(chartData)

    const handleEmailSubmit = (details) => {
        setProgress(true)
        const sendData = {
            message: notes, sendToEmail: sendToEmail,
            subject: `${details.City} - Work Order Details`, city: details.City,
            orderSize: details.orderSize, fieldEngineer: details.totalWorker,
            hours:details.totalHours
        }
        // console.log("calling send email", details,
        //     sendData
        // )
        sendWorkOrderEmailToUser(sendData)
            .then(response => {
                console.log("email response", response);
                if(response.error){
                    setMessageSent(response.error); setProgress(false)
                }
                else{
                    setEmailDisplay(!props.emailDisplay)
                setMessageSent('Sent successfully'); setProgress(false)
                }
            })
    }


    const defaultProps = {
        center:mapCenters[continent[selectedContinent]] ,
        zoom: 4.6
    };


    const getMapBounds = (map, maps,chartData,zoom) => {
        console.log("z",zoom,chartData)
        const bounds = new maps.LatLngBounds();
        // map.setZoom(zoom)
        chartData.forEach((place) => {
            console.log("place",place)
            bounds.extend(new maps.LatLng(
                place.lat,
                place.lng,
            ));
        });
        console.log("bouds",bounds)
        return bounds;
    };

    const handleApiLoaded = (map, maps,chartData,zoom) => {
        // Get bounds by our places
        console.log("api loaded",map,maps,chartData,zoom)
        const bounds = getMapBounds(map, maps,chartData,zoom);
        // Fit map to bounds
        map.fitBounds(bounds);
        // Bind the resize listener
        // bindResizeListener(map, maps, bounds);
    };

    return (
        // Important! Always set the container height explicitly
        <div style={{ height: '80vh', width: '100%' }}>
            <GoogleMapReact
                //bootstrapURLKeys={{ key: 'AIzaSyBE1Eq6c1U0ysEjm-gZ0HDKDUcwj28VzcU' }}
                bootstrapURLKeys={{ key: 'AIzaSyBGOfuRbdlaKeI9RB8U35jl_djFUvS4O_o' }}
                defaultCenter={mapCenters[continent[selectedContinent]].center}
                defaultZoom={4.3}
                zoom={mapCenters[continent[selectedContinent]].zoom}
                center={mapCenters[continent[selectedContinent]].center}
                // onClick={(e) => {
                //     console.log("latitude = ", e.lat,e)
                //     console.log("longtitude = ", e.lng);
                // }}
            //     resetBoundsOnResize={true}
            //     yesIWantToUseGoogleMapApiInternals={true}
            //   onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps,chartData,mapCenters[continent[selectedContinent]].zoom)}
                options={{
                    panControl: false,
                    mapTypeControl: false,
                    scrollwheel: false,
                    styles: [{ stylers: [{ 'saturation': -10 }, { 'gamma': 3 }, { 'lightness': 1 }, { 'visibility': 'on' }] }]

                }}
            >

                {chartData.map((item, k) => {
                    // console.log('avg',item.total_probability*100,item.avg_probability*100,item.city)
                    return (
                        <BubbleComponent
                            key={k}
                            lat={item.lat}
                            lng={item.lng}
                            text={{ 'City': item.city, 'orderSize': Math.floor(item.job_size).toLocaleString("en-US"),'totalWorker': Math.floor(item.total_field_engineer).toLocaleString("en-US"),'totalHours': Math.floor(item.total_hours).toLocaleString("en-US") }}
                            size={item.job_size}
                            emailDisplay={emailDisplay}
                            setEmailDisplay={setEmailDisplay}
                            sendToEmail={sendToEmail}
                            setSendToEmail={setSendToEmail}
                            notes={notes}
                            setNotes={setNotes}
                            handleEmailSubmit={handleEmailSubmit}
                            open={open}
                            setOpen={setOpen}
                            messageSent={messageSent}
                            setMessageSent={setMessageSent}
                            progress={progress}

                        />

                    )
                })}



            </GoogleMapReact>
        </div>
    );
}
